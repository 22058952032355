const blockTestimonials = (Swiper, Autoplay, Pagination, Navigation) => {
    const block = document.querySelector('[data-testimonials]');

    if (!block) return;

    // eslint-disable-next-line no-unused-vars
    const swiper = new Swiper(block, {
        modules: [Autoplay, Pagination, Navigation],
        slidesPerView: 1,
        autoplay: true,
        speed: 1000,
        delay: 3000,
        loop: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
};
export default blockTestimonials;
