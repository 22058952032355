const display = (block, item) => {
    const className = 'active';
    const { carouselTabsItem } = item.dataset;
    const actives = block.querySelectorAll(`.${className}`);
    actives.forEach((el) => {
        el.classList.remove(className);
    });
    const text = block.querySelector(`[data-carousel-tabs-texts="${carouselTabsItem}"]`);
    if (!text) return;

    text.classList.add(className);
    item.classList.add(className);
};

const blockCarouselTabs = () => {
    const blocks = document.querySelectorAll('[data-carousel-tabs]');
    if (!blocks) return;

    blocks.forEach((el) => {
        const block = el;
        const items = block.querySelectorAll('[data-carousel-tabs-item]');

        items.forEach((elItem) => {
            const item = elItem;

            item.onclick = () => {
                display(block, item);
                block.scrollIntoView();
            };

            item.onmouseenter = () => {
                display(block, item);
            };
        });
    });
};

export default blockCarouselTabs;
