const selectPlaceholder = () => {
    const selectCity = document.querySelector('[data-select-city]');
    if (!selectCity) return;

    selectCity.onchange = () => {
        if (selectCity.value !== '' && selectCity.classList.contains('placeholder'))
            selectCity.classList.remove('placeholder');
    };

    const selectObject = document.querySelector('[data-select-object]');
    selectObject.onchange = () => {
        if (selectObject.value !== '' && selectObject.classList.contains('placeholder'))
            selectObject.classList.remove('placeholder');
    };
};

export default selectPlaceholder;
