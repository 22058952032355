const blockHero = () => {
    const animation = document.querySelector('[data-hero-animation]');
    if (!animation) return;
    window.onscroll = () => {
        // if (window.pageYOffset > 100) animation.classList.add('active');
        // else animation.classList.remove('active');
        if (window.matchMedia('(max-width: 991.98px)').matches) {
            animation.style.maxWidth = `calc(95% + ${window.pageYOffset / 10}%)`;
        } else {
            animation.style.maxWidth = `calc(80% + ${window.pageYOffset / 10}%)`;
        }
        animation.style.top = `calc(15% - ${window.pageYOffset / 10}%)`;
    };
};

export default blockHero;
