const customEventForm = () => {
    document.addEventListener('wpcf7mailsent', (e) => {
        const { inputs } = e.detail;

        /* Add tracking GTM -4 */

        window.dataLayer = window.dataLayer || [];

        const allDataEvent = {
            event: 'formSubmission',
            formVariant: 'Form - cf7',
        };

        /* eslint-disable */
        const mapFieldsNames = {
            'your-name': 'nameSurname',
            'your-email': 'email',
            'your-phone': 'phone',
            'your-city': 'city',
            'your-offer': 'local',
            'select_offer': 'service',
            'your-message': 'message',
        };
        /* eslint-enable */

        if (inputs && Array.isArray(inputs)) {
            inputs.forEach((el) => {
                const inpName = el.name;
                const inpVal = el.value;

                if (inpName in mapFieldsNames) {
                    const newKey = mapFieldsNames[inpName];
                    allDataEvent[newKey] = inpVal;
                }
            });
        }

        window.dataLayer.push(allDataEvent);
    });
};

export default customEventForm;
