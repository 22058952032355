const blockOffer = () => {
    const btns = document.querySelectorAll('[data-box-offer-btn]');

    if (!btns) return;

    btns.forEach((el) => {
        const btn = el;

        btn.onclick = () => {
            const { idOffer, idCity, anchor } = btn.dataset;

            const city = document.querySelector(`option[value="${idCity}"]`);
            if (city) {
                city.selected = true;
            }

            const offer = document.querySelector(`option[value="${idCity} - ${idOffer}"]`);
            if (offer) {
                offer.selected = true;
            }

            const form = document.querySelector(anchor);
            if (form) {
                form.scrollIntoView({ behavior: 'auto', block: 'start', inline: 'start' });
            }
        };
    });
};

export default blockOffer;
