const blockCities = () => {
    const cities = document.querySelectorAll('[data-block-city-item]');
    if (!cities) return;

    cities.forEach((el) => {
        const city = el;
        const { blockCityItem } = city.dataset;
        if (!blockCityItem) return;

        const block = city.closest('section');
        city.onmouseenter = () => {
            block.style.setProperty('--cities-background-image', `url(${blockCityItem})`);
            setTimeout(() => {
                block.classList.add('active');
            }, 100);
        };

        city.onmouseleave = () => {
            block.classList.remove('active');

            block.style.setProperty('--cities-background-image', '');
        };
    });
};

export default blockCities;
