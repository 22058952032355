import changeUrl from './changeUrl';

/* eslint-disable */

/**
 * Setting active header
 */


const settingActiveHeader = (elHeader,nameActive,val) => {

    const text = elHeader.querySelector('.js-text');
    const staticText = elHeader.getAttribute('data-staticText');
    const allActive = text.textContent.split(', ');

    // if(text){
    //     if(allActive.includes(nameActive)){
    //       const index = allActive.indexOf(nameActive);
    //       if (index !== -1) allActive.splice(index, 1);
    //     }else{
    //       allActive.push(nameActive);
    //     }
    // }

    // //removed static text
    // if(allActive.includes(staticText)){
    //   const index = allActive.indexOf(staticText);
    //   if (index !== -1) allActive.splice(index, 1);
    // }
    
     let activeCovert = nameActive;

    // if(elHeader.classList.contains('js-single')){
    //   activeCovert = nameActive;
    // }

    if(elHeader && activeCovert !== ''){
      elHeader.classList.add('active');
      if(text && nameActive) text.textContent = activeCovert;
    }else{
      elHeader.classList.remove('active'); 
      if(staticText) text.textContent = staticText;
    }

  };

/**
 * Loader do postów
 */

const postsLoader = (loader, show) => {
    if (loader) {
        if (show) {
            loader.style.opacity = 1;
            loader.style.visibility = 'visible';
        } else {
            loader.style.opacity = 0;
            loader.style.visibility = 'hidden';
        }
    }
};

/**
 * Schowanie dropdown's
 */

const hideDropdowns = () => {
    const formCheckboxes = document.querySelectorAll('.c-filter-field--active');
    formCheckboxes.forEach((el) => el.classList.remove('c-filter-field--active'));
};

/**
 * Schowanie dropdown's po kliknięciu za ich obszar
 */

const catchClickOutside = () => {
    window.addEventListener('click', (e) => {
        const formCheckboxes = document.querySelectorAll('.c-filter-field--active');
        formCheckboxes.forEach((el) => {
            if (!el.contains(e.target) && !e.target.classList.contains('month__arrow')) {
                el.classList.remove('c-filter-field--active');
            }
        });
    });
};

/**
 * Wysłanie formularza w tle
 */
const submitFilters = () => {
    const form = document.querySelector('.c-filters__form');
    const evt = new Event('submit', {
        bubbles: true,
        cancelable: true,
    });
    form.dispatchEvent(evt);
};

const filters = () => {
    const form = document.querySelector('.c-filters__form');
    const loadeMore = document.querySelector('.js-load-more-posts:not(.js-markered)');

    document.querySelectorAll('.c-filter-field__header').forEach((el) => {
        el.addEventListener('click', (e) => {
            e.currentTarget.closest('.c-filter-field').classList.toggle('c-filter-field--active');
            catchClickOutside();
        });
    });

    if (form) {
        form.addEventListener('submit', (e) => {
            e.preventDefault();

            const loader = document.querySelector('.js-posts-loader');
            const countSearch = document.querySelector('.js-found-result-count');

            if (loader) postsLoader(loader, true);

            const formdata = new FormData(form);

            const ajax = new XMLHttpRequest();

            // eslint-disable-next-line no-undef
            ajax.open('POST', mainVariables.ajaxUrl, true);
            ajax.onreadystatechange = () => {
                const filtersResults = document.querySelector('.js-filters-results');

                if (ajax.readyState === 4 && ajax.status === 200) {
                    if (ajax.responseText) {
                        const json = JSON.parse(ajax.responseText);

                        if (json.success) {
                            // console.log(json.data.count);
                            filtersResults.innerHTML = json.data.html && json.data.html;
                        } else {
                            filtersResults.innerHTML = json.data.html && json.data.html;
                        }

                        if (json?.data?.countAll && countSearch) {
                            countSearch.textContent = json.data.countAll;
                        }

                        if (json?.data?.count > 0 && json?.data?.normalLoaded) {
                            if (loadeMore) {
                                loadeMore.setAttribute('data-count', json.data.count);
                                loadeMore.classList.remove('d-none');
                            }
                        } else if (loadeMore) loadeMore.classList.add('d-none');
                    }

                    if (loader) postsLoader(loader, false);
                }
            };

            ajax.send(formdata);
        });

        const formInputsWrapper = form.querySelectorAll('.c-filters__single');

        if (formInputsWrapper) {
            formInputsWrapper.forEach((wrap) => {
                const formInputs = wrap.querySelectorAll('.c-filter-field__input--radio');
                const formInputsExtend = wrap.querySelectorAll('.js-enable-uncheck');
                const elHeader = wrap.querySelector('.c-filter-field__header');

                formInputs.forEach((el) => {
                    el.addEventListener('change', (e) => {
                        const elTarg = e.currentTarget;
                        let nameActive = elTarg.getAttribute('data-name');
                        const val = elTarg.value;
                        const staticTextHeader = elHeader.getAttribute('data-staticText');

                        formInputs.forEach((el) =>{
                             el.parentNode.classList.remove('active');
                            });

                        elTarg.parentNode.classList.add('active');

                        if(elHeader.classList.contains('js-multiple') && val !== 'all'){
                            let renderName = staticTextHeader;
                            let countCheck = 0;

                            formInputs.forEach(inpChekc =>{
                                if(inpChekc.val == 'all') inpChekc.checked = false;
                                if(inpChekc.checked) countCheck++;
                            });

                            if(countCheck > 0){
                                renderName += ' ('+ countCheck +')'; 
                            }

                            nameActive = renderName;
                        }

                        if(val === 'all' && elHeader.classList.contains('js-multiple')){
                            nameActive = staticTextHeader;
                       
                            formInputs.forEach(inpChekc =>{
                                if(inpChekc.checked) inpChekc.checked = false;
                            });
                        }
                        

                        settingActiveHeader(elHeader, nameActive, val);
                        hideDropdowns();
                        submitFilters();
                    });
                });

                if (formInputsExtend) {
                    formInputsExtend.forEach((el) => {
                        el.addEventListener('click', (e) => {
                            const elTarg = e.currentTarget;
                            if (elTarg.checked && elTarg.parentNode.classList.contains('active')) {
                                elTarg.checked = false;
                                elTarg.parentNode.classList.remove('active');
                                submitFilters();
                            }
                        });
                    });
                }
            });
        }

        /**
         * Load more post
         */

        if (loadeMore) {
            loadeMore.addEventListener('click', (e) => {
                const targ = e.currentTarget;
                const numPost = targ.getAttribute('data-count') * 1;
                const increase = targ.getAttribute('data-increase') * 1;

                const inputCountPost = form.querySelector('.js-input-count-post');

                if (inputCountPost && numPost) {
                    inputCountPost.value = numPost + increase;
                    // update url
                    changeUrl(`count=${numPost + increase}`);
                    submitFilters();
                }
            });
        }

        document.body.addEventListener('click', (e) => {
            const targ = e.target;

            if (targ.classList.contains('js-load-more-posts') && targ.classList.contains('js-markered')) {
                e.preventDefault();

                const numPost = targ.getAttribute('data-count') * 1;
                const increase = targ.getAttribute('data-increase') * 1;
                const marker = targ.getAttribute('data-marker');

                if (increase && numPost && marker) {
                    const inputName = `change_count_${marker}`;
                    const input = form.querySelector(`input[name="${inputName}"]`);

                    if (input) {
                        input.value = numPost + increase;
                    } else {
                        const input = document.createElement('input');
                        input.type = 'hidden';
                        input.name = inputName;
                        input.value = numPost + increase;
                        form.appendChild(input);
                    }

                    submitFilters();
                }
            }
        });
    }
};

/* eslint-enable */

export default filters;
