const menu = () => {
    const header = document.querySelector('.js-header');
    const switcherMobile = document.querySelectorAll('.js-toggle');
    const mobileMenu = document.querySelector('.js-mobile-menu');

    if (switcherMobile) {
        switcherMobile.forEach((el) => {
            el.addEventListener('click', (e) => {
                e.currentTarget.classList.toggle('active');
                if (mobileMenu) mobileMenu.classList.toggle('is-active');
                if (header) header.classList.toggle('is-active');
            });
        });
    }

    if (mobileMenu) {
        const linksMenu = mobileMenu.querySelectorAll('.js-link-menu');

        if (linksMenu) {
            linksMenu.forEach((item) => {
                item.addEventListener('click', () => {
                    mobileMenu.classList.toggle('is-active');
                    if (header) header.classList.toggle('is-active');
                    if (switcherMobile) switcherMobile.forEach((el) => el.classList.toggle('active'));
                });
            });
        }
    }

    if (header) {
        window.addEventListener('scroll', () => {
            if (window.pageYOffset > 150) header.classList.add('fixed');
            else header.classList.remove('fixed');
        });
    }
};

export default menu;
