const blockGallerySlider = (Swiper, Autoplay, Pagination, Navigation) => {
    const blocks = document.querySelectorAll('.js-gallery-slider');

    if (!blocks) return;

    blocks.forEach((block) => {
        // eslint-disable-next-line no-unused-vars
        const swiper = new Swiper(block, {
            modules: [Autoplay, Pagination, Navigation],
            slidesPerView: 1.1,
            autoplay: true,
            speed: 1000,
            delay: 3000,
            loop: false,
            pagination: false,
            spaceBetween: 24,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                568: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                },
                768: {
                    slidesPerView: 2.2,
                    spaceBetween: 24,
                },
            },
        });
    });
};
export default blockGallerySlider;
