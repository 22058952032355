const selectChoose = () => {
    const selectCity = document.querySelector('[data-select-city]');
    const selectService = document.querySelector('[data-select-object]');

    if (!selectCity || !selectService) return;

    const updateSelectTownOptions = () => {
        const selectedCity = selectCity.value;
        const selectedServiceCity = selectService.options[selectService.selectedIndex].getAttribute('data-city');

        selectService.querySelectorAll('option').forEach((option) => {
            const cityFromService = option.getAttribute('data-city');
            if (selectedCity !== '') {
                // eslint-disable-next-line no-param-reassign
                option.style.display = cityFromService === selectedCity ? 'block' : 'none';
                // eslint-disable-next-line no-param-reassign
                option.disabled = cityFromService !== selectedCity;

                if (cityFromService === selectedCity) {
                    // eslint-disable-next-line no-param-reassign
                    option.classList.remove('hidden-option');
                } else {
                    // eslint-disable-next-line no-param-reassign
                    option.classList.add('hidden-option');
                }
            }
        });

        if (selectedCity !== selectedServiceCity && selectedServiceCity !== '' && selectedCity !== '') {
            selectService.selectedIndex = 0;
            selectService.dispatchEvent(new Event('input', { bubbles: true }));
        }
    };

    const updateSelectServices = () => {
        const selectedService = selectService.value;
        const getSelectCityOptions = selectCity.querySelectorAll('option');

        if (getSelectCityOptions.length > 0 && selectedService) {
            getSelectCityOptions.forEach((option) => {
                const getCityFromService = option.value;
                if (selectedService === getCityFromService) {
                    // eslint-disable-next-line no-param-reassign
                    option.selected = true;
                    updateSelectTownOptions();
                }
            });
        }
    };

    selectCity.addEventListener('input', updateSelectTownOptions);
    selectService.addEventListener('input', updateSelectServices);

    // Instrukcje, które inicjują stan początkowy
    updateSelectTownOptions();
};

export default selectChoose;
