const selectConditional = () => {
    const selectCity = document.querySelector('[data-select-city]');
    if (!selectCity) return;

    selectCity.onchange = () => {
        const selectObject = document.querySelector('[data-select-object]');

        if (!selectObject) return;
        Array.from(selectObject.options).forEach((el) => {
            if (el.dataset.city !== selectCity.value) el.setAttribute('disabled', true);
            else el.removeAttribute('disabled');
        });
    };
};

export default selectConditional;
