const blockIcons = (Swiper, Autoplay) => {
    const block = document.querySelector('[data-icons]');

    if (!block) return;

    // eslint-disable-next-line no-unused-vars
    const swiper = new Swiper(block, {
        modules: [Autoplay],
        slidesPerView: 1.5,
        centeredSlides: true,
        autoplay: true,
        speed: 1000,
        delay: 3000,
        loop: true,
        // autoHeight: true,
        spaceBetween: 15,
        breakpoints: {
            992: {
                slidesPerView: 6,
                centeredSlides: false,
            },
            768: {
                slidesPerView: 4,
                centeredSlides: false,
            },
        },
    });
};
export default blockIcons;
