/*
  Project: biurabiznesowe
  Author: WebCrafters Studio
 */

import Swiper, { Autoplay, Pagination, Navigation } from 'swiper';
import AOS from 'aos';
import blockCarouselTabs from './blocks/carousel-tabs';
import blockCities from './blocks/cities';
import blockTestimonials from './blocks/testimonials';
import blockIcons from './blocks/icons';
import blockHero from './blocks/hero';
import menu from './modules/menu';
import blockGallerySlider from './blocks/gallery-slider';
import blockOffer from './blocks/offer';
import selectConditional from './modules/select-conditional';
import selectPlaceholder from './modules/select-placeholder';
import filters from './modules/fitlers';
import selectChoose from './modules/select-choose';
import customEventForm from './modules/customEventForm';

document.addEventListener('DOMContentLoaded', () => {
    blockCities();
    blockCarouselTabs();
    blockTestimonials(Swiper, Autoplay, Pagination, Navigation);
    blockIcons(Swiper, Autoplay);
    blockHero();
    blockOffer();
    menu();
    selectConditional();
    selectPlaceholder();
    blockGallerySlider(Swiper, Autoplay, Pagination, Navigation);
    filters();
    selectChoose();
    customEventForm();

    const customSelect = document.querySelectorAll('.custom-select');

    if (customSelect) {
        customSelect.forEach((el) => {
            const select = el;
            select.parentElement.classList.add('custom-select-wrapper');
        });
    }

    const wpmlSwitcher = document.querySelector('.js-wpml-ls-sub-menu');

    if (wpmlSwitcher) {
        const wpmlBtn = document.querySelector('.js-wpml-ls-item-toggle');
        const wpmlObserver = new MutationObserver((mutations) => {
            mutations.forEach((el) => {
                const mutation = el;
                if (mutation.target.style.visibility === 'visible') {
                    if (wpmlBtn) {
                        wpmlBtn.classList.add('active');
                    }
                } else if (mutation.target.style.visibility === 'hidden') {
                    if (wpmlBtn) {
                        wpmlBtn.classList.remove('active');
                    }
                }
            });
        });

        const wpmlObserverConfig = {
            childList: false,
            attributes: true,
            subtree: false,
        };

        wpmlSwitcher.onclick = () => {
            wpmlSwitcher.classList.toggle('active');
        };

        wpmlObserver.observe(wpmlSwitcher, wpmlObserverConfig);
    }

    if (typeof AOS !== 'undefined' && AOS.init) {
        AOS.init({
            offset: 200,
            duration: 600,
            once: true,
        });
    }
});
